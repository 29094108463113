import { gql } from "urql";

export const GetInawoVendorServiceCategory = gql`
  query GetInawoVendorServiceCategory {
    getInawoVendorServiceCategory {
      id
      Name
      Description
      Images
    }
  }
`;

export const GetVendorServiceByCategory = gql`
  query GetVendorServiceByCategory(
    $vendorServiceCategoryId: UUID!
    $page: Int
    $pageSize: Int
  ) {
    getVendorServiceByCategory(
      vendorServiceCategoryId: $vendorServiceCategoryId
    ) {
      id
      interests
      vendors_id
      price {
        currency
        amount
      }
      vendor_service_category_id
      category {
        id
        Name
      }
      service_name
      pinned
      description
      phone_number
      rating
      email
      address
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_id
        vendor_service_category_id
        media
      }
    }
  }
`;

export const GetFeaturedVendorServices = gql`
  query GetFeaturedVendorServices($page: Int, $pageSize: Int) {
    getFeaturedVendorServices {
      id
      vendors_id
      vendor_service_category_id
      service_name
      pinned
      category {
        Name
      }
      showcase(page: $page, pageSize: $pageSize) {
        id
        media
        name
        vendor_service_id
        vendors_id
        vendor_service_category_id
      }
    }
  }
`;

export const User = gql`
  query User {
    user {
      id
      image
      email
      phone_number
      first_name
      last_name
      title
      uuid
    }
  }
`;

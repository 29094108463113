import * as React from "react";
import Image from "next/image";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { NextPageWithLayout } from "@pages/_app";
import HomeLayout from "@layouts/home";
import { v4 as uuidv4 } from "uuid";
import fetch from "isomorphic-fetch";
import Navbar from "@components/navbar";
import ServiceCarousel from "@components/service-carousel";
import FeatureCarousel from "@components/featured-carousel";
import Button from "@components/button";
import { GetServerSideProps } from "next";
import Link from "next/link";
import {
  cacheExchange,
  ssrExchange,
  makeOperation,
  Operation,
  dedupExchange,
  fetchExchange,
  OperationResult,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import { withUrqlClient, initUrqlClient } from "next-urql";
import {
  deleteAccessCookie,
  deleteRefreshCookie,
  getAccessCookie,
  getRefreshCookie,
  setAccessCookie,
  setRefreshCookie,
} from "@utils/storage";
import {
  GetFeaturedVendorServicesQuery,
  GetFeaturedVendorServicesQueryVariables,
  GetInawoVendorServiceCategoryQuery,
  GetInawoVendorServiceCategoryQueryVariables,
  GetMyVendorProfileQuery,
  GetVendorServiceByCategoryQuery,
  GetVendorServiceByCategoryQueryVariables,
  RefreshQuery,
} from "@graphql/types";
import {
  GetInawoVendorServiceCategory,
  GetVendorServiceByCategory,
  GetFeaturedVendorServices,
} from "@graphql/queries/home.query";
import { AuthState } from "@utils/auth";
import Banner1 from "@public/assets/banner.png";
import Banner2 from "@public/assets/banner2.png";
import toast from "react-hot-toast";
import Head from "next/head";
import { GetMyVendorProfile } from "@graphql/queries/vendor.query";

type Props = {
  refreshPage: boolean;
  vendorsProfileResult: OperationResult<GetMyVendorProfileQuery> | null;
  featuredResult: OperationResult<
    GetFeaturedVendorServicesQuery,
    GetFeaturedVendorServicesQueryVariables
  > | null;
  categoryResult: OperationResult<
    GetInawoVendorServiceCategoryQuery,
    GetInawoVendorServiceCategoryQueryVariables
  > | null;
};

const Home: NextPageWithLayout<Props> = (props) => {
  React.useEffect(() => {
    if (props.refreshPage) {
      toast.error(<p className="toast">Your session has expired.</p>, {
        duration: 10000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [props.refreshPage]);

  //   const [categoryResult] = useQuery<
  //     GetInawoVendorServiceCategoryQuery,
  //     GetInawoVendorServiceCategoryQueryVariables
  //   >({
  //     query: GetInawoVendorServiceCategory,
  //   });

  //   const [featuredResult] = useQuery<
  //     GetFeaturedVendorServicesQuery,
  //     GetFeaturedVendorServicesQueryVariables
  //   >({
  //     query: GetFeaturedVendorServices,
  //   });

  return (
    <>
      <Head>
        <title>Inawo Vendors</title>
        <meta name="title" content="Inawo Vendors" />
        <meta name="description" content="Inawo Vendors" />
      </Head>
      <div className="w-full">
        <Navbar vendorsProfileResult={props.vendorsProfileResult} />

        {props.categoryResult?.error ? (
          <>An error occured</>
        ) : !!props.categoryResult?.data &&
          !!props.categoryResult?.data.getInawoVendorServiceCategory ? (
          props.categoryResult?.data?.getInawoVendorServiceCategory
            .slice(
              0,
              props.categoryResult?.data.getInawoVendorServiceCategory.length /
                2
            )
            .map((item) => (
              <ServiceCarousel
                priority
                name={item!.Name}
                id={item!.id}
                key={uuidv4()}
              />
            ))
        ) : (
          <></>
        )}

        <FeatureCarousel featured={props.featuredResult} />

        <div className="my-14 overflow-hidden relative bg-gray-300 rounded-xl p-8 xs:p-16 min-h-[20rem] flex flex-col gap-4 justify-center items-end">
          <Image
            src={Banner1}
            placeholder="blur"
            alt="become a vendor"
            objectFit="cover"
            layout="fill"
            quality={90}
            priority
          />

          <div className="flex flex-col justify-center z-[1] items-center gap-4">
            <h2 className="uppercase text-white text-md xs:text-lg font-sfpro tracking-widest text-center font-light">
              Become a <br /> Vendor
            </h2>
            <Link href="/become-a-vendor">
              <a>
                <Button label="Become a vendor" type="button">
                  Become a Vendor
                </Button>
              </a>
            </Link>
          </div>
        </div>

        {props.categoryResult?.error ? (
          <>An error occured</>
        ) : !!props.categoryResult?.data &&
          !!props.categoryResult?.data.getInawoVendorServiceCategory ? (
          props.categoryResult?.data?.getInawoVendorServiceCategory
            .slice(
              props.categoryResult?.data.getInawoVendorServiceCategory.length /
                2,
              props.categoryResult?.data.getInawoVendorServiceCategory.length
            )
            .map((item) => (
              <ServiceCarousel
                priority
                name={item!.Name}
                id={item!.id}
                key={uuidv4()}
              />
            ))
        ) : (
          <></>
        )}

        <div className="my-14 overflow-hidden relative bg-inawo-green-200 rounded-xl p-8 xs:p-16 min-h-[20rem] flex flex-col gap-4 justify-center items-center">
          <Image
            src={Banner2}
            placeholder="blur"
            alt="become a vendor"
            objectFit="cover"
            layout="fill"
            quality={90}
            priority
          />

          <div className="flex flex-col justify-center items-center gap-4 z-[1]">
            <h2 className="uppercase text-lg text-white xs:text-xl font-sfpro tracking-widest text-center font-light">
              Get vendors <br /> for your event
            </h2>
            <Link href="/search">
              <a>
                <Button label="Explore Vendors" type="button">
                  Explore Vendors
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <HomeLayout>{page}</HomeLayout>;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { req, res } = ctx;
  let refreshPage = false;
  const accessToken = getAccessCookie({ req, res });
  const ssrCache = ssrExchange({ isClient: false });
  const client = initUrqlClient(
    {
      url: process.env.NEXT_PUBLIC_API_URL!,
      requestPolicy: "cache-and-network",
      fetchOptions: {
        headers: {
          "x-user-token": accessToken ? `${accessToken}` : "",
        },
      },
      exchanges: [
        dedupExchange,
        cacheExchange,
        authExchange({
          addAuthToOperation: ({
            authState,
            operation,
          }: {
            authState: AuthState;
            operation: Operation;
          }) => {
            // the token isn't in the auth state, return the operation without changes
            if (!authState || !authState.accessToken) {
              return operation;
            }

            // fetchOptions can be a function (See Client API) but you can simplify this based on usage
            const fetchOptions =
              typeof operation.context.fetchOptions === "function"
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {};

            return makeOperation(operation.kind, operation, {
              ...operation.context,
              requestPolicy: "network-only",
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  "x-user-token": authState.accessToken
                    ? `${authState.accessToken}`
                    : "",
                },
              },
            });
          },
          willAuthError: ({ authState }) => {
            //if there's no authState the trigger the auth arror
            if (!authState) return true;
            // e.g. check for expiration, existence of auth etc
            const accessTokenDecoded = jwt_decode<JwtPayload>(
              authState.accessToken as string
            );
            const refreshTokenDecoded = jwt_decode<JwtPayload>(
              authState.refreshToken as string
            );

            // if access token will almost expire
            if (
              !!accessTokenDecoded?.exp &&
              Date.now() >= accessTokenDecoded?.exp * 1000
            ) {
              return true;
            }

            // if refresh token will almost expire
            if (
              !!refreshTokenDecoded?.exp &&
              Date.now() >= refreshTokenDecoded?.exp * 1000
            ) {
              return true;
            }
            return false;
          },
          didAuthError: ({ error }) => {
            // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
            return error.graphQLErrors.some((e) =>
              e.message.includes("unauthenticated")
            );
          },
          getAuth: async ({ authState }) => {
            // for initial launch, fetch the auth state from storage (local storage, async storage etc)
            if (!authState) {
              const accessToken = getAccessCookie({ req, res }) as string;
              const refreshToken = getRefreshCookie({ req, res }) as string;

              if (accessToken && refreshToken) {
                return { accessToken, refreshToken };
              }
              return null;
            }

            /**
             * the following code gets executed when an auth error has occurred
             * we should refresh the token if possible and return a new auth state
             * If refresh fails, we should log out
             **/

            const resultInitial = await fetch(
              process.env.NEXT_PUBLIC_API_URL!,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  query: `
										query Refresh($refresh_token: String!) {
								refresh(refresh_token: $refresh_token) {
									refresh_token
									access_token
									access_expiry
								}
							}
					`,
                  variables: {
                    refresh_token: authState.refreshToken,
                  },
                }),
              }
            );

            const result: { data: RefreshQuery; errors: any } =
              await resultInitial.json();

            if (result.data?.refresh) {
              // save the new tokens in storage for next restart
              setRefreshCookie(result.data.refresh.refresh_token, {
                req,
                res,
              });
              setAccessCookie(result.data.refresh.access_token, { req, res });

              return {
                accessToken: result.data.refresh.access_token,
                refreshToken: result.data.refresh.refresh_token,
              };
            }

            // otherwise, if refresh fails, log clear storage and log out
            console.log("Vendors RefreshQuery Home page Error :>> ", result);
            deleteAccessCookie({ req, res });
            deleteRefreshCookie({ req, res });

            // your app logout logic should trigger here
            refreshPage = true;

            return null;
          },
        }),
        ssrCache,
        fetchExchange,
      ],
    },
    false
  );

  let vendorsProfileResult: OperationResult<GetMyVendorProfileQuery> | null =
    null;

  try {
    if (client) {
      vendorsProfileResult = await client
        .query<GetMyVendorProfileQuery>(GetMyVendorProfile)
        .toPromise();
    }
  } catch (error) {
    console.log("Vendors GetMyVendorProfileQuery Home Error :>> ", error);
  }

  let featuredResult: OperationResult<
    GetFeaturedVendorServicesQuery,
    GetFeaturedVendorServicesQueryVariables
  > | null = null;

  try {
    if (client) {
      featuredResult = await client
        .query<
          GetFeaturedVendorServicesQuery,
          GetFeaturedVendorServicesQueryVariables
        >(GetFeaturedVendorServices)
        .toPromise();
    }
  } catch (error) {
    console.log("Vendors GetFeaturedVendorServices Error :>> ", error);
  }

  let categoryResult: OperationResult<
    GetInawoVendorServiceCategoryQuery,
    GetInawoVendorServiceCategoryQueryVariables
  > | null = null;

  try {
    if (client) {
      categoryResult = await client
        .query<
          GetInawoVendorServiceCategoryQuery,
          GetInawoVendorServiceCategoryQueryVariables
        >(GetInawoVendorServiceCategory)
        .toPromise();

      categoryResult.data?.getInawoVendorServiceCategory &&
        categoryResult.data?.getInawoVendorServiceCategory?.map(
          async (item) => {
            try {
              await client
                .query<
                  GetVendorServiceByCategoryQuery,
                  GetVendorServiceByCategoryQueryVariables
                >(GetVendorServiceByCategory, {
                  vendorServiceCategoryId: item?.id,
                })
                .toPromise();
            } catch (error) {
              console.log(
                "Vendors GetVendorServiceByCategory Error :>> ",
                error
              );
            }
          }
        );
    }
  } catch (error) {
    console.log("Vendors GetInawoVendorServiceCategory Error :>> ", error);
  }

  return {
    props: {
      urqlState: ssrCache.extractData(),
      categoryResult: JSON.parse(JSON.stringify(categoryResult)),
      featuredResult: JSON.parse(JSON.stringify(featuredResult)),
      vendorsProfileResult: JSON.parse(JSON.stringify(vendorsProfileResult)),
      refreshPage,
    },
  };
};

export default withUrqlClient(
  (_ssr) => ({
    url: process.env.NEXT_PUBLIC_API_URL!,
    requestPolicy: "cache-and-network",
    fetchOptions: () => {
      return {
        headers: {
          "x-user-token": getAccessCookie() ? `${getAccessCookie()}` : "",
        },
      };
    },
  }),
  { ssr: false }
)(Home);

import * as React from "react";
import FloatingBarHome from "@components/floating-bar-home";
import { hasAccessCookie } from "@utils/storage";
import { Toaster } from "react-hot-toast";

type Props = {
  children: React.ReactNode;
};

const HomeLayout: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        containerStyle={{
          zIndex: "2147483647",
          isolation: "isolate",
          transformStyle: "preserve-3d",
          transform: "translateZ(1000px)",
          bottom: 90,
        }}
        toastOptions={{
          duration: 2000,
          success: {
            style: {
              background: "#376C65",
              border: "0.5px dashed #376C65",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px 50px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              textAlign: "center",
              color: "#fff",
              borderRadius: "15px",
              zIndex: "2147483647",
              isolation: "isolate",
              transformStyle: "preserve-3d",
              transform: "trasnslateZ(1000px)",
            },
          },
          error: {
            style: {
              background: "#6C3737",
              border: "0.5px dashed #6C3737",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "19px",
              textAlign: "center",
              color: "#fff",
              borderRadius: "15px",
              zIndex: "2147483647",
              isolation: "isolate",
              transformStyle: "preserve-3d",
              transform: "trasnslateZ(1000px)",
            },
          },
        }}
      />

      <div className="z-[0] w-full relative overflow-x-hidden bg-white">
        {show && hasAccessCookie() ? <FloatingBarHome /> : <></>}
        <div className="z-[-1] bg-inawo-bg-green-top-bg w-screen fixed top-0 h-1/3" />
        <div className="w-full max-w-[1300px] mx-auto z-[3] pb-24 px-4 md:px-8 flex flex-col justify-start min-h-full items-center gap-1">
          {props.children}
        </div>
        <div className="z-[-1] bg-inawo-bg-grey-bottom-bottom-bg w-screen fixed bottom-0 h-1/5" />
      </div>
    </>
  );
};

export default HomeLayout;

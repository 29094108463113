import { gql } from "urql";

export const PinVendorService = gql`
  mutation PinVendorService($vendorServiceId: UUID!) {
    pinVendorService(vendorServiceId: $vendorServiceId)
  }
`;

export const UnpinVendorService = gql`
  mutation UnPinVendorService($vendorServiceId: UUID!) {
    unPinVendorService(vendorServiceId: $vendorServiceId)
  }
`;

export const BookVendorService = gql`
  mutation BookVendorService(
    $vendorServiceId: UUID!
    $date: Time!
    $eventUuid: UUID
  ) {
    bookVendorService(
      vendorServiceId: $vendorServiceId
      date: $date
      eventUUID: $eventUuid
    )
  }
`;

export const RateVendorService = gql`
  mutation RateVendorService(
    $vendorServiceId: UUID!
    $rating: Int!
    $comment: String!
    $bookingId: UUID!
  ) {
    rateVendorService(
      vendorServiceId: $vendorServiceId
      rating: $rating
      comment: $comment
      bookingId: $bookingId
    ) {
      id
      vendors_id
      vendor_service_id
      vendor_bookings_id
      comment
      rating
      user_uuid
      event_uuid
    }
  }
`;

export const CreateMyVendorService = gql`
  mutation CreateMyVendorService($req: CreateMyVendorServiceReq!) {
    createMyVendorService(req: $req) {
      id
      service_name
      vendors_id
      vendor_service_category_id
      category {
        id
        Name
      }
      email
      phone_number
      description
    }
  }
`;

export const CreateMyVendorProfile = gql`
  mutation CreateMyVendorProfile($req: CreateMyVendorProfileReq!) {
    createMyVendorProfile(req: $req) {
      id
      user_uuid
      brand_name
      description
      phone_number
      email
      address
    }
  }
`;

export const CancelVendorServiceBookings = gql`
  mutation CancelVendorServiceBookings($bookingId: UUID!) {
    cancelVendorServiceBookings(bookingId: $bookingId)
  }
`;

export const AcceptVendorServiceBookings = gql`
  mutation AcceptVendorServiceBookings(
    $bookingsId: UUID!
    $status: vendorServiceBookingStatus!
  ) {
    acceptVendorServiceBookings(bookingsId: $bookingsId, status: $status)
  }
`;

export const DeleteMyVendorService = gql`
  mutation DeleteMyVendorService($vendorServiceId: UUID!) {
    deleteMyVendorService(vendorServiceId: $vendorServiceId)
  }
`;

export const CreateMyVendorServiceShowcase = gql`
  mutation CreateMyVendorServiceShowcase($req: CreateMyVendorServiceShowcase!) {
    createMyVendorServiceShowcase(req: $req) {
      id
      vendors_id
      vendor_service_category_id
      vendor_service_id
      name
      media
      description
      tags
    }
  }
`;

export const DeleteMyVendorServiceShowcase = gql`
  mutation DeleteMyVendorServiceShowcase(
    $serviceId: UUID!
    $showcaseId: UUID!
  ) {
    deleteMyVendorServiceShowcase(
      serviceId: $serviceId
      showcaseId: $showcaseId
    )
  }
`;

export const CreateMyVendorServiceShowcases = gql`
  mutation CreateMyVendorServiceShowcases(
    $serviceId: UUID!
    $req: [CreateMyVendorServiceShowcase!]!
  ) {
    createMyVendorServiceShowcases(serviceId: $serviceId, req: $req)
  }
`;

export const UpdateMyVendorService = gql`
  mutation UpdateMyVendorService(
    $req: UpdateMyVendorServiceReq!
    $page: Int
    $pageSize: Int
  ) {
    updateMyVendorService(req: $req) {
      id
      email
      description
      category {
        id
        Name
      }
      address
      phone_number
      price {
        amount
        currency
      }
      service_name
      showcase(page: $page, pageSize: $pageSize) {
        id
        media
        vendor_service_category_id
      }
      vendors_id
      vendor_service_category_id
    }
  }
`;

import * as React from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import SmallFeaturedCard from "@components/small-featured-card";
import {
  GetFeaturedVendorServicesQuery,
  GetFeaturedVendorServicesQueryVariables,
} from "@graphql/types";
import { OperationResult } from "urql";

type Props = {
  featured: OperationResult<
    GetFeaturedVendorServicesQuery,
    GetFeaturedVendorServicesQueryVariables
  > | null;
};

const FeatureCarousel: React.FC<Props> = (props) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-4 my-10">
      <div className="flex items-center justify-between w-full">
        <h4 className="font-sfpro font-light text-base xs:text-lg text-inawo-grey-500 tracking-wider uppercase">
          Featured Vendors
        </h4>
        <Link href={`/search`}>
          <a className="font-semibold text-xs xs:text-sm text-inawo-grey-500 font-sfpro">
            Browse Vendors
          </a>
        </Link>
      </div>
      <div className="w-full">
        {props?.featured?.error ? (
          <></>
        ) : props?.featured?.data &&
          !!props?.featured?.data.getFeaturedVendorServices ? (
          <Swiper
            spaceBetween={8}
            slidesPerView={2.2}
            loop
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 2.2,
                spaceBetween: 8,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2.6,
                spaceBetween: 10,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3.2,
                spaceBetween: 15,
              },
              789: {
                slidesPerView: 3.8,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4.4,
                spaceBetween: 15,
              },
              1440: {
                slidesPerView: 5.5,
                spaceBetween: 15,
              },
              1840: {
                slidesPerView: 6.5,
                spaceBetween: 20,
              },
              2200: {
                slidesPerView: 7.8,
                spaceBetween: 20,
              },
            }}
          >
            {props.featured.data.getFeaturedVendorServices.map((item) => (
              <SwiperSlide key={uuidv4()}>
                <SmallFeaturedCard
                  categoryName={item?.category?.Name ?? ""}
                  id={item?.id}
                  vendorName={item?.service_name ?? ""}
                  image={item?.showcase![0]?.media}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="h-44 sm:h-60 md:h-80" />
        )}
      </div>
    </div>
  );
};

export default FeatureCarousel;

import * as React from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import SmallServiceCard from "@components/small-service-card";
import { useQuery } from "urql";
import { GetVendorServiceByCategory } from "@graphql/queries/home.query";
import {
  GetVendorServiceByCategoryQuery,
  GetVendorServiceByCategoryQueryVariables,
} from "@graphql/types";
import { Spinner } from "@components/spinner";

type Props = {
  name: string;
  id: string;
  priority: boolean;
};

const ServiceCarousel: React.FC<Props> = (props) => {
  const [categoryResult] = useQuery<
    GetVendorServiceByCategoryQuery,
    GetVendorServiceByCategoryQueryVariables
  >({
    query: GetVendorServiceByCategory,
    pause: !props.id,
    variables: {
      vendorServiceCategoryId: props.id,
      page: 1,
      pageSize: 20,
    },
  });

  return (
    <div className="w-full flex flex-col justify-start items-start gap-4 my-10">
      <div className="flex items-center justify-between w-full">
        <h4 className="font-sfpro font-light text-base xs:text-lg text-inawo-grey-500 tracking-wider uppercase">
          {props.name}
        </h4>
        <Link href={`/categories/${props.id}`}>
          <a className="font-semibold text-xs xs:text-sm text-inawo-grey-500 hover:text-inawo-green-200 transition font-sfpro">
            see more
          </a>
        </Link>
      </div>
      <div className="w-full">
        {categoryResult.fetching ? (
          <div className="h-36 sm:h-52 md:h-72 flex justify-center items-center">
            <Spinner />
          </div>
        ) : categoryResult.error ? (
          <></>
        ) : categoryResult.data ? (
          <Swiper
            spaceBetween={8}
            slidesPerView={3.2}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 3.2,
                spaceBetween: 8,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 3.6,
                spaceBetween: 10,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 4.2,
                spaceBetween: 15,
              },
              789: {
                slidesPerView: 4.8,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 5.4,
                spaceBetween: 15,
              },
              1440: {
                slidesPerView: 6.5,
                spaceBetween: 15,
              },
              1840: {
                slidesPerView: 7.5,
                spaceBetween: 20,
              },
              2200: {
                slidesPerView: 8.8,
                spaceBetween: 20,
              },
            }}
          >
            {categoryResult.data?.getVendorServiceByCategory!.map((item) => (
              <SwiperSlide key={uuidv4()}>
                <SmallServiceCard
                  categoryName={props.name}
                  id={item!.id}
                  pinned={item?.pinned ?? false}
                  image={
                    item!.showcase!.length > 0 ? item!.showcase![0]!.media! : ""
                  }
                  vendorName={item!.service_name}
                  priority={props.priority}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ServiceCarousel;

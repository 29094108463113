import * as React from "react";
import blurDataUrl from "@utils/shimmer";
import Link from "next/link";
import Image from "next/image";
import Sample from "@public/assets/sample.png";

type Props = {
  id: string | undefined;
  image: string | null | undefined;
  categoryName: string | undefined;
  vendorName: string;
};

const SmallFeaturedCard: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col justify-start items-start gap-2 relative h-44 sm:h-60 md:h-80">
      <Link href={`/profile/${props?.id ?? 0}`}>
        <a className="w-full h-full">
          <div className="relative bg-inawo-green hover:brightness-90 transition overflow-hidden p-4 w-full h-full rounded-[10px] flex flex-col justify-end items-start gap-1">
            <Image
              src={!!props.image ? props.image : Sample}
              placeholder="blur"
              blurDataURL={blurDataUrl(400, 400)}
              objectFit="cover"
              objectPosition="center"
              layout="fill"
              quality={75}
              priority
              sizes="40vw"
              alt={`${props?.vendorName ?? ""} ${
                props?.categoryName ?? ""
              } service`}
            />
            <p className="bg-black/50 z-[2] px-1 py-1 uppercase font-sfpro text-xs xs:text-sm text-white font-light tracking-widest">
              {props?.categoryName ?? "Unavailable"}
            </p>
            <p className="line-clamp-2 z-[2] text-sm xs:text-base font-sfpro font-semibold text-white">
              {props?.vendorName ?? "Unavailable"}
            </p>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default SmallFeaturedCard;

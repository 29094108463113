import { Spinner } from "../spinner";

interface ButtonProps {
  children: React.ReactNode;
  label: string;
  handleClick?: () => void;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  loader?: boolean;
  design?: "primary" | "secondary" | "tertiary";
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  label,
  handleClick,
  disabled,
  loader,
  design = "primary",
}) => {
  return (
    <button
      onClick={handleClick}
      aria-label={label}
      type={type}
      className={`${
        design === "primary" &&
        "w-full bg-inawo-green active:bg-inawo-green px-6 py-3 shadow flex justify-center items-center rounded-[0.75rem] transition hover:bg-inawo-green/95 disabled:cursor-not-allowed disabled:bg-inawo-grey-200"
      } ${
        design === "secondary" &&
        "w-full bg-inawo-red active:bg-inawo-red px-6 py-3 shadow flex justify-center items-center rounded-[0.75rem] transition hover:bg-inawo-red/95 disabled:cursor-not-allowed disabled:bg-inawo-grey-200"
      }`}
      disabled={disabled}
    >
      <span className="text-white font-light text-base">
        {loader ? <Spinner /> : children}
      </span>
    </button>
  );
};

export default Button;
